<template>
  <MiscellaneousLanguage dialog-name="Languages" />
</template>
<script>
import MiscellaneousLanguage from '@/views/app/miscellaneous/Language'

export default {
  name: 'LanguageWrapper',
  components: {
    MiscellaneousLanguage
  }
}
</script>
